import { Loader } from '@profgeosoft-ui/react';
import { ScopeDIProvider, requireService } from '@profgeosoft/di-ez';
import { useCallback, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { DirectoryFormSidebar } from 'src/features/directory-form-sidebar';
import { DirectoryPageHeader } from 'src/features/directory-page-header';
import { wrap } from 'src/packages/mobx-di/wrap';
import { DirectoryPageHeaderService } from 'src/services/directory-page-header-service';

import styles from './directory-page.module.scss';

export const DirectoryPage = wrap(function DirectoryPage() {
  const navigate = useNavigate();
  const directoryListService = requireService('directoriesListService');
  const directoryPage = requireService('directoryPage');
  const [directoryPageHeaderService] = useState(() => new DirectoryPageHeaderService());

  const isLoading = directoryPage.isAdditionalDirectoryInitializing || directoryPage.tableSettings.isSettingsLoading;

  const init = useCallback(
    () => ({
      directoryPageHeader: directoryPageHeaderService,
    }),
    [directoryPageHeaderService],
  );

  const openExemplarDirectory = () => {
    if (!directoryListService.subDirectoryViews || !directoryListService.subDirectoryViews.length) {
      return;
    }

    const view = directoryListService.subDirectoryViews[0];
    navigate(view.objectType);
  };

  return (
    <ScopeDIProvider init={init}>
      {isLoading && <Loader size="l" className={styles.loader} />}

      <DirectoryPageHeader
        directoryPageHeaderService={directoryPageHeaderService}
        mainDirectory={directoryPage.currentDirectory}
        isOpenExemplarsButtonVisible={
          !!directoryListService.subDirectoryViews && !directoryListService.currentSubDirectory
        }
        onChangeVisibleDirectory={openExemplarDirectory}
      />

      <div className={styles.wrapper}>{<Outlet />}</div>

      {directoryPage.additionalDirectories.map((directory) => {
        return (
          <DirectoryFormSidebar
            key={directory.entityId}
            directory={directory}
            onCancel={directoryPage.removeLastAdditionalDirectory}
            onSave={() => directory.saveChanges(directoryPage.removeLastAdditionalDirectory)}
          />
        );
      })}
    </ScopeDIProvider>
  );
});

declare module '@profgeosoft/di-ez' {
  export interface ServicesCollectionMap {
    directoryPageHeader: DirectoryPageHeaderService;
  }
}
