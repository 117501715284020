import { isPrimitive, isPrimitivesArray } from 'src/packages/types';
import { getAttrKey } from 'src/packages/utils/get-attr-key';

import type { DirectoryRecord } from './entities/directory-record.entity';
import type { TControlView, TEmbeddedArrayValue, TSerializedRecord } from './types';
import type { TPrimitive, TPrimitiveArray } from 'src/packages/types';

function checkIsValidControlValue(value: unknown): value is TPrimitive | TPrimitiveArray {
  return isPrimitive(value) || isPrimitivesArray(value);
}

function isEmbeddedArray(value: unknown): value is TEmbeddedArrayValue[] {
  return !!value && Array.isArray(value) && value.every((v) => typeof v === 'object');
}

export function serializeDirectoryRecordToSave(record: DirectoryRecord): TSerializedRecord {
  const serializedRecord: TSerializedRecord = {};

  for (const control of record.controls) {
    if (!control.attrName) {
      continue;
    }

    if (control.value === null || checkIsValidControlValue(control.value)) {
      if (control.view.isEmbeddedArray) {
        // If the control is part of the embedded array attrName is a string like "Common_WellPassing.data.passingByWellPurpose.10048.passing".
        // This string corresponds to the schema:
        // 0 - directory name,
        // 1 - "data", static string,
        // 2 - record key,
        // 3 - embedded field id,
        // 4 - embedded field name,
        const [directoryName, _, key, fieldId, fieldName] = control.attrName.split('.');

        if (key) {
          const existingArray = serializedRecord[key];
          const [fieldIdKey] = control.fieldId.split('.');

          const embeddedArrayRecord: TEmbeddedArrayValue = {
            [fieldIdKey]: fieldId,
            [fieldName]: control.value,
          };

          if (isEmbeddedArray(existingArray)) {
            existingArray.push(embeddedArrayRecord);
          } else {
            serializedRecord[key] = [embeddedArrayRecord];
          }
        }
      } else {
        const key = control.attrName.split('.').at(-1);

        if (key) {
          serializedRecord[key] = control.value;
        }
      }
    }
  }

  return serializedRecord;
}

/** Parses serialized record data from database/localstorage and returns a map like
 * { control_attr_name: control_value } */
export function deserializeDirectoryRecord(
  recordData: Record<string, unknown>,
  controlViews: TControlView[],
): Record<string, TPrimitive | TPrimitiveArray | null | undefined> {
  const controlValues: Record<string, TPrimitive | TPrimitiveArray | null | undefined> = {};

  for (const controlView of controlViews) {
    if (!controlView.attrName) {
      continue;
    }

    if (controlView.isEmbeddedArray) {
      const [directoryName, dataStaticString, key, fieldId, fieldName] = controlView.attrName.split('.');
      const [fieldIdKey, fieldIdValue] = controlView.fieldId.split('.');
      const value = recordData[key];

      if (isEmbeddedArray(value)) {
        const controlValue = value.find((object) => object[fieldIdKey]?.toString() === fieldIdValue.toString());

        if (controlValue) {
          controlValues[controlView.attrName] = controlValue[fieldName];
        }
      }
    } else {
      const attrKey = getAttrKey(controlView.attrName);
      const value = recordData[attrKey];

      if (!isEmbeddedArray(value) && (isPrimitive(value) || isPrimitivesArray(value))) {
        controlValues[controlView.attrName] = value;
      }
    }
  }

  return controlValues;
}
