import { CloseRoundLight, MenuNewLightCustom } from '@profgeosoft-ui/icons';
import { Button } from '@profgeosoft-ui/react';
import { requireService } from '@profgeosoft/di-ez';
import clsx from 'clsx';
import { useEffect, useRef } from 'react';

import { wrap } from 'src/packages/mobx-di/wrap';
import { checkIsNode } from 'src/packages/utils/checkIsNode';

import { Logo } from './logo/logo';
import { Navigation } from './navigation/navigation';
import { Switchers } from './switchers/switchers';
import { UserSection } from './user-section/user-section';

import styles from './sidebar.module.scss';

type Props = {
  isOpened: boolean;
  open(): void;
  close(): void;
  toggle(): void;
};

export const Sidebar = wrap<Props>(function Sidebar({ isOpened, open, close, toggle }) {
  const userService = requireService('userService');
  const ref = useRef<HTMLDivElement>(null);

  const userData = userService.getUserData();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!checkIsNode(event.target)) return;
      if (ref.current!.contains(event.target)) return;
      close();
    };

    document.body.addEventListener('pointerdown', handleClickOutside);

    return () => {
      document.body.removeEventListener('pointerdown', handleClickOutside);
    };
  }, [close]);

  return (
    <>
      <div ref={ref} className={clsx(styles.wrapper, !isOpened && styles.wrapper__closed)}>
        <div>
          <div className={styles.logoAndCloseContainer}>
            <Logo />
            <CloseRoundLight className={styles.closeButton} onClick={close} />
          </div>
          <Navigation isOpened={isOpened} onCloseSidebar={close} />
        </div>
        <div>
          <Switchers isOpened={isOpened} />
          <UserSection isOpened={isOpened} userInfo={userData} onLogout={userService.logout} />
        </div>
      </div>
      <Button className={clsx(styles.openButton, isOpened && styles.openButton__active)} onClick={toggle}>
        <MenuNewLightCustom className={styles.openButtonIcon} />
      </Button>
    </>
  );
});
