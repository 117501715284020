import { flow, makeObservable, observable } from 'mobx';

import { ApiError, OperationAbortedError } from 'src/packages/errors';
import { hasValue } from 'src/packages/utils/has-value';

import type { AxiosInstance } from 'axios';
import type { IUserService } from '../auth-service/user-service';
import type { INotificationsService } from '../notifications-service';

import { UserSettingsServiceApi } from './user-settings-service.api';

export type TUserSettings = {
  ownerUserId: string;
  settings: TUserSettingsObject;
};

export type TUserSettingsObject = {
  favoriteDirectories?: string[];
};

export class UserSettingsService {
  private readonly notifications: INotificationsService;
  private readonly api: UserSettingsServiceApi;

  @observable private settingsId: number | null = null;
  @observable settings: TUserSettings | null = null;

  constructor(agent: AxiosInstance, userService: IUserService, notificationsService: INotificationsService) {
    this.notifications = notificationsService;
    this.api = new UserSettingsServiceApi(agent, userService);

    makeObservable(this);
  }

  private async createSettings(): Promise<{ id: number; data: TUserSettings } | null> {
    await this.api.createSettings();

    return this.api.loadSettings();
  }

  private async getSettings(): Promise<{ id: number; data: TUserSettings } | null> {
    try {
      const settings = await this.api.loadSettings();

      if (settings) {
        return settings;
      } else {
        return await this.createSettings();
      }
    } catch (e) {
      if (e instanceof ApiError && e.statusCode === 404) {
        return await this.createSettings();
      }

      throw e;
    }
  }

  @flow.bound
  async *getOrCreateSettings() {
    if (this.settings) {
      return this.settings.settings;
    }

    try {
      const settings = await this.getSettings();

      if (settings) {
        yield;

        this.settingsId = settings.id;
        this.settings = settings.data;
      }

      return this.settings?.settings;
    } catch (e) {
      yield;

      if (e instanceof OperationAbortedError) {
        return null;
      }

      if (e instanceof ApiError) {
        if (e.message) {
          this.notifications.showErrorMessage(e.message);
        }
      }

      this.notifications.showErrorMessageT('settings:table.errors.failedToLoadSettings');
    }

    return null;
  }

  @flow.bound
  async *updateSettings(settings: TUserSettingsObject) {
    if (!hasValue(this.settingsId) || !this.settings) {
      return null;
    }

    const newSettings = {
      ...this.settings,
      settings: { ...this.settings.settings, ...settings },
    };

    this.settings = newSettings;

    try {
      await this.api.updateSettings(this.settingsId, this.settings);

      return this.getOrCreateSettings();
    } catch (e) {
      yield;

      if (e instanceof OperationAbortedError) {
        return null;
      }

      if (e instanceof ApiError && e.message) {
        this.notifications.showErrorMessage(e.message);
        return null;
      }

      this.notifications.showErrorMessageT('settings:table.errors.failedToUpdateTableSettings');
    }

    return null;
  }
}
