import { TableMultiSelect, TableSelect } from '@profgeosoft-ui/react';
import { reaction } from 'mobx';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { wrap } from 'src/packages/mobx-di/wrap';
import { MultiComboboxField } from 'src/services/directory-service/entities/controls/multicombobox-field';

import type { GridRenderEditCellParams } from '@profgeosoft-ui/react';
import type { TOption } from 'src/packages/types';
import type { ComboboxField } from 'src/services/directory-service/entities/controls/combobox-field';
import type { DirectoryRecord } from 'src/services/directory-service/entities/directory-record.entity';
import type { OptionsService } from 'src/services/directory-service/options-service';
import type { IControl, TComboBoxView, TMultiComboBoxView } from 'src/services/directory-service/types';

import { ComboboxDropdown } from '../../../combobox-dropdown';

import styles from './edit-mode-combobox.module.scss';

type Props = {
  params: GridRenderEditCellParams<DirectoryRecord>;
  control: ComboboxField | MultiComboboxField;
  controlView: TComboBoxView | TMultiComboBoxView;
  optionsService: OptionsService;
  onCreateNewRecord: VoidFunction;
  onChange(value: unknown): void;
  onBlur(control: IControl): void;
};

export const EditComboboxCell = wrap<Props>(function EditComboboxCell({
  params,
  control,
  controlView,
  optionsService,
  onChange,
  onBlur,
  onCreateNewRecord,
}) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(params.hasFocus);

  const [internalOptions, setInternalOptions] = useState<TOption[]>(() => {
    if (controlView.refQuery) {
      return [];
    }

    return optionsService.options[control.fieldId] ?? [];
  });

  useEffect(() => {
    if (!controlView.refQuery) {
      return;
    }

    const abortController = new AbortController();

    control.setIsLoading(true);

    optionsService
      .getOptionsByRefQuery(controlView, params.row.controls, abortController.signal)
      .then((options) => setInternalOptions(options))
      .finally(() => control.setIsLoading(false));

    return () => abortController.abort();
  }, [control, controlView, controlView.refQuery, optionsService, params.row.controls]);

  useEffect(() => {
    return reaction(
      () => optionsService.options[control.fieldId] ?? [],
      (options) => setInternalOptions(options),
      { fireImmediately: true },
    );
  }, [control.fieldId, optionsService.options]);

  const isOpened = isOpen && !control.isLoading && !control.isDisabled && !control.isEnteringBlocked;

  if (control instanceof MultiComboboxField) {
    const value = control.value.map(
      (value) =>
        internalOptions.find((o) => o.value === value) ||
        optionsService.archivedOptions[control.fieldId]?.find((o) => o.value === value),
    );

    return (
      <TableMultiSelect
        cellParams={params}
        value={value}
        open={isOpened}
        className={styles.select}
        placeholder={t('common:placeholders.chooseValue')}
        status={control.error ? 'error' : undefined}
        loading={control.isLoading}
        options={internalOptions}
        optionFilterProp="label"
        dropdownMatchSelectWidth={false}
        onDropdownVisibleChange={(open) => setIsOpen(open)}
        popupClassName={styles.popupWrapper}
        dropdownRender={(menu) => (
          <ComboboxDropdown onButtonClick={onCreateNewRecord} onClose={() => setIsOpen(false)}>
            {menu}
          </ComboboxDropdown>
        )}
        onChange={onChange}
        onBlur={() => onBlur(control)}
        allowClear
      />
    );
  }

  const valueOption =
    internalOptions.find((o) => o.value === control.value) ||
    optionsService.archivedOptions[control.fieldId]?.find((o) => o.value === control.value);

  return (
    <TableSelect
      cellParams={params}
      value={valueOption}
      open={isOpened}
      className={styles.select}
      optionFilterProp="label"
      loading={control.isLoading}
      filterOption
      showSearch
      placeholder={t('common:placeholders.chooseValue')}
      status={control.error ? 'error' : undefined}
      options={internalOptions}
      dropdownMatchSelectWidth={false}
      onDropdownVisibleChange={(open) => setIsOpen(open)}
      popupClassName={styles.popupWrapper}
      dropdownRender={(menu) => (
        <ComboboxDropdown onButtonClick={onCreateNewRecord} onClose={() => setIsOpen(false)}>
          {menu}
        </ComboboxDropdown>
      )}
      onChange={onChange}
      onSelect={onChange}
      onBlur={() => onBlur(control)}
      allowClear
    />
  );
});
