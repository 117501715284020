import {
  AddRoundLight,
  ChooseRow,
  CloseRoundLight,
  DetailVertCustom,
  DoneLight,
  EditLightBig,
  SettingLineLight,
} from '@profgeosoft-ui/icons';
import { Button, Tooltip } from '@profgeosoft-ui/react';
import { requireService } from '@profgeosoft/di-ez';
import clsx from 'clsx';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { wrap } from 'src/packages/mobx-di/wrap';

import type { MainDirectoryService } from 'src/services/directory-service';

import styles from '../directory-page-header.module.scss';

type Props = {
  directory: MainDirectoryService;
  onTableSettingsClick(): void;
};

export const EditModeControls = wrap<Props>(function EditModeControls({ directory, onTableSettingsClick }) {
  const directoryPageHeader = requireService('directoryPageHeader');
  const directoryPage = requireService('directoryPage');
  const directoryListService = requireService('directoriesListService');
  const { t } = useTranslation('appbar');

  const reloadData = directoryPage.directoryModelService?.loadTableData || directory.loadData;

  const discardChanges = () => {
    if (!directory.hasChanges) {
      directoryPage.setIsEditMode(false);
      directoryPage.setIsRowSelectionMode(false);
      directoryPage.removeSavedEditModeData();
      return;
    }

    directoryPageHeader.onEditModeOffByDiscard(() => {
      directory.discardChanges();
      directoryPage.removeSavedEditModeData();
    });
  };

  useEffect(() => {
    directoryListService.setIsDirectoryChangingBlocked(directoryPage.isEditing);
  }, [directoryListService, directoryPage.isEditing]);

  if (!directoryPage.isEditing) {
    return (
      <>
        <Button
          variant="flat"
          className={styles.iconWrapper}
          onClick={() => {
            directoryPage.setIsEditMode(true);
            directoryPage.setIsRowSelectionMode(true);
          }}
        >
          <EditLightBig />
        </Button>
        <Button variant="flat" className={styles.iconWrapper} onClick={onTableSettingsClick}>
          <SettingLineLight />
        </Button>
        <Button
          className={clsx(styles.chooseRowButton, directoryPage.isRowSelectionModeOn && styles.pressed)}
          onClick={() => {
            directoryPage.setIsRowSelectionMode(!directoryPage.isRowSelectionModeOn);
          }}
        >
          <ChooseRow />
        </Button>
      </>
    );
  }

  return (
    <div className={styles.editModeWrapper}>
      <Button onClick={() => directory.addNewRecord()} icon={<AddRoundLight />}>
        {t('appbar.addButton')}
      </Button>
      <Button variant="danger" className={styles.quaterButton} onClick={discardChanges}>
        <CloseRoundLight />
      </Button>
      <Button
        variant="success"
        disabled={!directory.hasChanges}
        className={styles.quaterButton}
        onClick={() =>
          directory.saveChanges(() => {
            reloadData();
            directoryPage.removeSavedEditModeData();
            directoryPage.setIsEditMode(false);
            directoryPage.setIsRowSelectionMode(false);
          })
        }
      >
        <DoneLight />
      </Button>
      <div className={styles.iconWrapper}>
        <DetailVertCustom />
      </div>
      <Tooltip title={t('tooltips.notAvialable')}>
        <Button variant="flat" className={clsx(styles.iconWrapper, styles.disabled)} disabled={directoryPage.isEditing}>
          <SettingLineLight />
        </Button>
      </Tooltip>
    </div>
  );
});
