import { wrap } from 'src/packages/mobx-di/wrap';

import type { LabelField } from 'src/services/directory-service/entities/controls/label-field';
import type { OptionsService } from 'src/services/directory-service/options-service';

import styles from './label-component.module.scss';

type Props = {
  control: LabelField;
  optionsService: OptionsService;
};

export const LabelComponent = wrap<Props>(function LabelComponent({ control, optionsService }) {
  const value = (() => {
    if (control.isSimpleLabel) {
      return control.value;
    }

    const options = [
      ...(optionsService.options[control.fieldId] ?? []),
      ...(optionsService.archivedOptions[control.fieldId] ?? []),
    ];

    return options?.find((opt) => opt.value === control?.value)?.label;
  })();

  return <p className={styles.text}>{value ?? '-'}</p>;
});
