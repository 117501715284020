export const tableSx = {
  '.MuiDataGrid-cellCheckbox.MuiDataGrid-cell': {
    borderRight: 'none',
    outline: 'none',
  },
  '.MuiDataGrid-columnHeaderCheckbox': {
    borderRight: 'none',
  },
  '.MuiDataGrid-columnSeparator .MuiDataGrid-columnSeparator--sideRight': {
    display: 'none',
  },
  '.controls-cell.MuiDataGrid-cell:focus-within, .MuiDataGrid-columnHeader.MuiDataGrid-withBorderColor:focus-within': {
    outline: 'none',
  },
  '.MuiDataGrid-pinnedColumns--right ': {
    borderRight: 'none',
  },
  '.MuiDataGrid-pinnedColumns, .MuiDataGrid-pinnedColumnHeaders': {
    backgroundColor: 'transparent',
  },
  '.MuiDataGrid-columnHeader .MuiDataGrid-columnHeader': {
    '--go-table-border-right': 'none',
  },
};
