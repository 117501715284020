import axios from 'axios';

import { getLocale } from 'src/packages/utils/get-locale';
import { getAccessToken } from 'src/services/auth-service/auth';
import { UserService } from 'src/services/auth-service/user-service';

import { API_URL } from './consts';

const agent = axios.create({
  baseURL: API_URL,
  headers: {
    'content-type': 'application/json',
  },
});

agent.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error?.response?.status === 401) {
      const accessToken = await UserService.updateToken();

      if (error?.config && accessToken) {
        return axios.request({
          ...error.config,
          headers: {
            ...error.config.headers,
            Authorization: `Bearer ${accessToken}`,
          },
        });
      }
    }

    return Promise.reject(error);
  },
);

agent.interceptors.request.use((config) => {
  const accessToken = getAccessToken();

  Object.entries(config.headers).forEach(([headerName, headerValue]) => {
    config.headers.set(headerName, headerValue);
  });

  config.headers['accept-language'] ??= getLocale();

  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  return config;
});

export { agent };
