import jwtDecode from 'jwt-decode';
import { default as KeycloakJS } from 'keycloak-js';

import { AUTH_API_URL } from 'src/api/consts';

import type { KeycloakConfig } from 'keycloak-js';

import { getAccessToken, getRefreshToken, removeTokens, setTokens } from './auth';
import { KEYCLOAK_CLIENT_ID, KEYCLOAK_REALM_NAME } from './consts';

export type UserInfo = {
  name?: string;
  preferred_username: string;
  email: string;
  family_name?: string;
  given_name?: string;
  sub: string;
};

export interface IAuthService {
  userInfo: UserInfo;
  logout(): void;
}

export interface IUserService {
  login(): Promise<void>;
  logout(): Promise<void>;
  getUserData(): UserInfo | null;
}

const keycloakConfig: KeycloakConfig = {
  realm: KEYCLOAK_REALM_NAME,
  clientId: KEYCLOAK_CLIENT_ID,
  url: AUTH_API_URL,
};

let keycloakAgent = new KeycloakJS(keycloakConfig);

export namespace UserService {
  export async function initUserService(): Promise<void> {
    keycloakAgent = new KeycloakJS(keycloakConfig);

    try {
      await keycloakAgent.init({
        flow: 'standard',
        scope: 'openid offline_access',
        token: getAccessToken() || undefined,
        refreshToken: getRefreshToken() || undefined,
      });
    } catch (e) {
      console.error(e);
    }
  }

  export async function initUserServiceWithCheck(): Promise<void> {
    keycloakAgent = new KeycloakJS(keycloakConfig);

    try {
      await keycloakAgent.init({
        flow: 'standard',
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
        pkceMethod: 'S256',
        scope: 'openid offline_access',
        refreshToken: getRefreshToken() || undefined,
      });

      if (keycloakAgent.authenticated && keycloakAgent.token) {
        setTokens(keycloakAgent.token, keycloakAgent.refreshToken);
      }

      if (keycloakAgent.refreshToken) {
        const accessToken = await updateToken();

        setTokens(accessToken);
      }
    } catch (e) {
      console.error(e);
    }
  }

  export async function updateToken(): Promise<string | undefined> {
    try {
      const refreshed = await keycloakAgent.updateToken(-1);

      const { token, refreshToken } = keycloakAgent;

      if (!refreshed || !token) {
        throw new Error('Failed to refresh token or new token is undefined.');
      }

      setTokens(token, refreshToken);

      return token;
    } catch (e) {
      await login();
      return;
    }
  }

  export function getIsAuthenticated(): boolean {
    return !!keycloakAgent.authenticated;
  }

  export function getUserData(): UserInfo | null {
    const token = getAccessToken();

    if (!token) return null;

    const tokenObject: UserInfo = jwtDecode(token);

    return {
      family_name: tokenObject.family_name,
      preferred_username: tokenObject.preferred_username,
      given_name: tokenObject.given_name,
      name: tokenObject.name,
      sub: tokenObject.sub,
      email: tokenObject.email,
    };
  }

  export const isUserInfo = (object: unknown): object is UserInfo =>
    typeof object === 'object' && !!object && 'name' in object && 'sub' in object;

  export async function login(): Promise<void> {
    removeTokens();
    try {
      await keycloakAgent.login();
    } catch (e) {
      console.error(e);
    }
  }

  export async function logout(): Promise<void> {
    removeTokens();
    await keycloakAgent.logout();
  }
}
