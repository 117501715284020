import { action, makeObservable, observable } from 'mobx';

import type { TRule } from './mappers';
import type { IDirectoriesStorage } from '../directories-storage-service/directories-storage-service';
import type { IControl } from '../directory-service/types';
import type { TControlView } from '../directory-service/types/controls-views.type';

import { mapControlRules } from './mappers';
import { handleValueSourceRule } from './rule-handlers/handle-value-source-rule';

export class ControlRulesService {
  private readonly directoriesStorageService: IDirectoriesStorage;
  readonly rules = observable.map<string, TRule[]>();

  constructor(directoriesStorageService: IDirectoriesStorage) {
    this.directoriesStorageService = directoriesStorageService;

    makeObservable(this);
  }

  @action.bound
  mapRulesFromControlViews(controls: TControlView[]): void {
    for (const control of controls) {
      const rules = mapControlRules(control);

      for (const { managmentControls, rule } of rules) {
        for (const managmentControl of managmentControls) {
          const rules: TRule[] = [...(this.rules.get(managmentControl) ?? []), rule];

          this.rules.set(managmentControl, rules);
        }
      }
    }
  }

  @action.bound
  onControlValueChange(controlsMap: Map<string, IControl<unknown>>, fieldId: string, value: unknown): void {
    const rules = this.rules.get(fieldId);

    if (!rules || !rules.length) {
      return;
    }

    rules.forEach((rule) => {
      switch (rule.type) {
        case 'valueSource': {
          const dependentControl = controlsMap.get(rule.dependentField);

          if (!dependentControl) {
            console.error(`cannot find corresponding dependent control. fieldId: ${rule.dependentField}`);
            break;
          }

          const { value } = handleValueSourceRule(this.directoriesStorageService, controlsMap, rule);
          dependentControl.setValue(value);

          break;
        }
      }
    });
  }
}
