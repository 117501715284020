import { action, computed, flow, makeObservable, observable } from 'mobx';

import { OperationAbortedError, ApiError } from 'src/packages/errors';
import { type TPrimitive, type TPrimitiveArray, type TOption, isPrimitive } from 'src/packages/types';
import { hasValue } from 'src/packages/utils/has-value';

import type {
  DirectoriesListService,
  TDependentDirectory,
} from 'src/services/directories-list-service/directories-list-service';
import type {
  IDirectoriesStorage,
  TGetObjectsParams,
} from 'src/services/directories-storage-service/directories-storage-service';
import type { MainDirectoryService } from 'src/services/directory-service';
import type { INotificationsService } from 'src/services/notifications-service';

export class DirectoryModelService {
  private readonly notificationsService: INotificationsService;
  private readonly directoriesList: DirectoriesListService;
  private readonly directoriesStorageService: IDirectoriesStorage;
  private readonly directoryService: MainDirectoryService;

  @observable private loadModelsAbortController: AbortController | null = null;
  @observable private models: Record<string, TPrimitive | TPrimitiveArray>[] = [];

  @observable isModelsLoading = false;
  @observable currentModelId: number | null = null;

  constructor(
    notificationsService: INotificationsService,
    directoriesList: DirectoriesListService,
    directoriesStorageService: IDirectoriesStorage,
    directoryService: MainDirectoryService,
  ) {
    this.notificationsService = notificationsService;
    this.directoriesList = directoriesList;
    this.directoriesStorageService = directoriesStorageService;
    this.directoryService = directoryService;

    makeObservable(this);
  }

  @flow.bound
  private async *loadModels(params?: TGetObjectsParams) {
    const subDirectoryView = this.view;
    const directoryView = this.directoriesList.currentDirectoryView;

    if (!subDirectoryView || !directoryView) {
      return;
    }

    this.isModelsLoading = true;

    if (this.loadModelsAbortController) {
      this.loadModelsAbortController.abort();
    }

    const abortController = new AbortController();
    this.loadModelsAbortController = abortController;

    try {
      const models = await this.directoriesStorageService.loadObjects(directoryView.objectType, false);
      yield;

      this.models = models.map((model) => ({ ...model.data, id: model.id }));
    } catch (e) {
      yield;

      if (e instanceof OperationAbortedError) {
        return;
      }
      console.error(e);
      if (e instanceof ApiError && e.message) {
        this.notificationsService.showErrorMessage(e.message);
        return;
      }

      this.notificationsService.showErrorMessageT('directory:errors.data.failedToLoadModels');
    } finally {
      this.isModelsLoading = false;
      this.loadModelsAbortController = null;
    }
  }

  @action.bound
  loadTableData(): void {
    const view = this.view;

    if (!view || !hasValue(this.currentModelId)) {
      return;
    }

    const filter: Record<string, unknown> = {};

    filter[view.refAttr] = this.currentModelId;

    this.directoryService.loadData({ filterMap: filter });
  }

  get view(): TDependentDirectory | null {
    return this.directoriesList.currentSubDirectoryView;
  }

  @computed
  get modelsList(): TOption[] {
    const view = this.view;

    if (!view) {
      return [];
    }

    const models: TOption[] = [];

    for (const rawModel of this.models) {
      const label = rawModel[view.mainDisplayAttr];
      const value = rawModel[view.mainAttr];

      if (!isPrimitive(label)) {
        console.warn('label is not a primitive', label, rawModel);
        continue;
      }

      if (typeof value !== 'number') {
        console.warn('value is not a number', value);
        continue;
      }

      models.push({ label: label.toString(), value });
    }

    return models;
  }

  @action.bound
  setCurrentModel(id: number | null): void {
    this.currentModelId = id;
    this.loadTableData();
  }

  init = () => {
    this.loadModels();

    return () => {
      this.loadModelsAbortController?.abort();
    };
  };
}
