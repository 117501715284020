import { requireService } from '@profgeosoft/di-ez';
import { Loader } from '@profgeosoft-ui/react';
import { useEffect } from 'react';

import { DirectoryTable } from 'src/components/directory-table/directory-table';
import { EditableDirectoryTable } from 'src/components/directory-table/editable-directory-table';
import { ErrorBoundary } from 'src/components/error-boundary/error-boundary';
import { ChooseRequiredValuesHint } from 'src/features/choose-required-values-hint/choose-required-values-hint';
import { wrap } from 'src/packages/mobx-di/wrap';

import type { ReactNode } from 'react';
import type { MainDirectoryService } from 'src/services/directory-service';

import styles from './directory.module.scss';

export const Directory = wrap(function Directory() {
  const directoryPage = requireService('directoryPage');
  const { currentDirectory, isRowSelectionModeOn, isMainDirectoryInitialized } = directoryPage;

  const renderContent = (directory: MainDirectoryService): ReactNode => {
    switch (directoryPage.currentDirectory?.status.type) {
      case 'error':
        return <ChooseRequiredValuesHint />;

      case 'ready':
        if (!directoryPage.isEditing) {
          return <DirectoryTable directory={directory} isRowSelectionModeOn={isRowSelectionModeOn} />;
        }

        return <EditableDirectoryTable directory={directory} />;

      default:
        return null;
    }
  };

  useEffect(() => {
    if (!currentDirectory) {
      return;
    }

    return currentDirectory.init();
  }, [currentDirectory]);

  useEffect(() => {
    currentDirectory?.generalControls.forEach((control) => control.setIsEnteringBlocked(directoryPage.isEditing));
  }, [currentDirectory?.generalControls, directoryPage.isEditing]);

  if (!currentDirectory) {
    return null;
  }

  const isSettingsLoading = currentDirectory.tableSettingsService.isSettingsLoading;

  return (
    <div className={styles.wrapper}>
      <ErrorBoundary errorStub={<div>Error</div>}>
        {isSettingsLoading || !isMainDirectoryInitialized ? <Loader /> : renderContent(currentDirectory)}
      </ErrorBoundary>
    </div>
  );
});
