import { InfoRoundLight } from '@profgeosoft-ui/icons';
import {
  GRID_ROW_NUMBER_COL_DEF,
  TableCheckbox,
  TableDatePicker,
  TableYearPicker,
  Tooltip,
} from '@profgeosoft-ui/react';
import { format, fromUnixTime, secondsToMilliseconds } from 'date-fns';
import { useMemo } from 'react';

import { assert } from 'src/packages/utils/assert';
import { CheckboxField } from 'src/services/directory-service/entities/controls/checkbox-field';
import { DateOnlyPickerField } from 'src/services/directory-service/entities/controls/date-only-picker';
import { MultiComboboxField } from 'src/services/directory-service/entities/controls/multicombobox-field';
import { YearOnlyPickerField } from 'src/services/directory-service/entities/controls/year-only-picker';

import type {
  GridColDef,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridValueFormatterParams,
  GridValueGetterParams,
} from '@profgeosoft-ui/react';
import type { MainDirectoryService } from 'src/services/directory-service';
import type { StringsList } from 'src/services/directory-service/entities/controls/strings-list';
import type { DirectoryRecord } from 'src/services/directory-service/entities/directory-record.entity';

import { Cell } from './components/cell/cell';
import { RecordButtons } from './components/records-button';
import { TableHeader } from './components/table-header';
import { ACTIVITY_COLUMN_FIELD } from './directory-table-mediator';

import styles from './directory-table.module.scss';

const baseColumn: Partial<GridColDef> = {
  minWidth: 30,
  headerAlign: 'left',
  align: 'left',
  flex: 1,
};

export const useTableColumns = (directory: MainDirectoryService): GridColDef[] => {
  const controlViews = directory.recordControlsViews;

  return useMemo(() => {
    const columns: GridColDef[] = [
      {
        ...GRID_ROW_NUMBER_COL_DEF,
        disableReorder: true,
        resizable: false,
        minWidth: 40,
        maxWidth: 45,
        renderCell: (params) => GRID_ROW_NUMBER_COL_DEF.renderCell?.(params),
      },
      {
        ...baseColumn,
        field: ACTIVITY_COLUMN_FIELD,
        width: 45,
        minWidth: 45,
        maxWidth: 45,
        sortable: false,
        disableReorder: true,
        disableColumnMenu: true,
        resizable: false,
        headerAlign: 'center',
        renderCell: (params: GridRenderCellParams<DirectoryRecord>) => (
          <Cell>
            <RecordButtons params={params} directory={directory} />
          </Cell>
        ),
        renderHeader: () => (
          <Tooltip title="Some info" placement="top">
            <InfoRoundLight className={styles.infoIcon} />
          </Tooltip>
        ),
      },
    ];

    for (const columnView of controlViews) {
      if ('innerColumns' in columnView) {
        continue;
      }

      switch (columnView.control) {
        case 'CheckBox': {
          columns.push({
            ...baseColumn,
            field: columnView.fieldId,
            filterable: false,
            sortable: true,
            disableColumnMenu: false,
            type: 'boolean',
            editable: false,
            valueGetter: (params: GridValueGetterParams<DirectoryRecord>) =>
              !!params.row.controlsMap.get(columnView.fieldId)?.value,
            renderHeader: () => <TableHeader textTKey={`labels:${columnView.fieldId}.label`} />,
            renderCell: (params: GridRenderCellParams<DirectoryRecord>) => {
              const control = params.row.controlsMap.get(columnView.fieldId);

              assert(control instanceof CheckboxField, 'control is not checkbox');

              return (
                <Cell message={control?.error}>
                  <TableCheckbox
                    cellParams={params}
                    checked={control.value}
                    disabled={true}
                    onChange={(value) => directory.onControlValueChange(params.row.recordId, control, value)}
                  />
                </Cell>
              );
            },
          });

          break;
        }

        case 'Field': {
          if (columnView.type === 'String') {
            columns.push({
              ...baseColumn,
              field: columnView.fieldId,
              type: 'string',
              valueGetter: (params: GridValueGetterParams<DirectoryRecord>) =>
                params.row.controlsMap.get(columnView.fieldId)?.value || '-',
              renderHeader: () => <TableHeader textTKey={`labels:${columnView.fieldId}.label`} />,
              renderCell: (params) => {
                const control = params.row.controlsMap.get(columnView.fieldId);

                return (
                  <Cell message={control?.error}>
                    <p className={styles.textEllipsis}>{params.value}</p>
                  </Cell>
                );
              },
            });
          }

          if (columnView.type === 'Number' || columnView.type === 'Integer') {
            columns.push({
              ...baseColumn,
              field: columnView.fieldId,
              type: 'string',
              valueGetter: (params: GridValueGetterParams<DirectoryRecord>) =>
                params.row.controlsMap.get(columnView.fieldId)?.value ?? '-',
              renderHeader: () => <TableHeader textTKey={`labels:${columnView.fieldId}.label`} />,
              renderCell: (params) => {
                const control = params.row.controlsMap.get(columnView.fieldId);

                return (
                  <Cell message={control?.error}>
                    <p className={styles.textEllipsis}>{params.value}</p>
                  </Cell>
                );
              },
            });
          }

          break;
        }

        case 'StringsList': {
          columns.push({
            ...baseColumn,
            field: columnView.fieldId,
            type: 'string',
            valueGetter: (params: GridValueGetterParams<DirectoryRecord>) => {
              const value = (params.row.controlsMap.get(columnView.fieldId) as StringsList)?.value;

              return value.length ? value.join(', ') : '-';
            },
            renderHeader: () => <TableHeader textTKey={`labels:${columnView.fieldId}.label`} />,
            renderCell: (params: GridRenderCellParams<DirectoryRecord>) => {
              const control = params.row.controlsMap.get(columnView.fieldId);

              return (
                <Cell message={control?.error}>
                  <p className={styles.textEllipsis}>{params.value}</p>
                </Cell>
              );
            },
          });

          break;
        }

        case 'YearOnlyPicker': {
          columns.push({
            ...baseColumn,
            field: columnView.fieldId,
            type: 'date',
            valueGetter: (params: GridValueGetterParams<DirectoryRecord>) => {
              const value = params.row.controlsMap.get(columnView.fieldId)?.value;

              if (!value || typeof value !== 'number') {
                return null;
              }

              return fromUnixTime(value);
            },
            valueFormatter: (params: GridValueFormatterParams<Date>) => {
              const { value } = params;

              if (!value) {
                return '-';
              }

              return format(value, 'yyyy');
            },
            renderHeader: () => <TableHeader textTKey={`labels:${columnView.fieldId}.label`} />,
            renderCell: (params) => {
              const control = params.row.controlsMap.get(columnView.fieldId);

              return (
                <Cell message={control?.error}>
                  <p className={styles.textEllipsis}>{params.value}</p>
                </Cell>
              );
            },
            renderEditCell: (params: GridRenderEditCellParams<DirectoryRecord>) => {
              const control = params.row.controlsMap.get(columnView.fieldId);

              assert(control instanceof YearOnlyPickerField, 'control is not a YearOnlyPickerField type');

              return (
                <Cell message={control.error} style={{ padding: 0 }}>
                  <TableYearPicker
                    cellParams={params}
                    value={control.value}
                    onChange={(value) => directory.onControlValueChange(params.row.recordId, control, value)}
                    onBlur={() => directory.onControlBlur(control)}
                  />
                </Cell>
              );
            },
          });

          break;
        }

        case 'DateOnlyPicker': {
          columns.push({
            ...baseColumn,
            field: columnView.fieldId,
            type: 'date',
            valueGetter: (params: GridValueGetterParams<DirectoryRecord>) => {
              const value = params.row.controlsMap.get(columnView.fieldId)?.value;

              if (!value || typeof value !== 'number') {
                return null;
              }

              return value;
            },
            valueFormatter: (params: GridValueFormatterParams<number>) => {
              const { value } = params;

              if (!value) {
                return '-';
              }

              return format(secondsToMilliseconds(value), 'dd.MM.yyyy');
            },
            renderHeader: () => <TableHeader textTKey={`labels:${columnView.fieldId}.label`} />,
            renderCell: (params) => {
              const control = params.row.controlsMap.get(columnView.fieldId);

              return (
                <Cell message={control?.error}>
                  <p className={styles.textEllipsis}>{params.formattedValue}</p>
                </Cell>
              );
            },
            renderEditCell: (params: GridRenderEditCellParams<DirectoryRecord>) => {
              const control = params.row.controlsMap.get(columnView.fieldId);

              assert(control instanceof DateOnlyPickerField, 'control is not a DateOnlyPickerField type');

              return (
                <Cell message={control.error} style={{ padding: 0 }}>
                  <TableDatePicker
                    cellParams={params}
                    dateFormat="dd.MM.yyyy"
                    showTime={false}
                    autoFocus={params.hasFocus}
                    onChange={(value) => directory.onControlValueChange(params.row.recordId, control, value)}
                    onBlur={() => directory.onControlBlur(control)}
                  />
                </Cell>
              );
            },
          });

          break;
        }

        case 'ComboBox': {
          columns.push({
            ...baseColumn,
            field: columnView.fieldId,
            type: 'string',
            valueGetter: (params: GridValueGetterParams<DirectoryRecord>) => {
              const control = params.row.controlsMap.get(columnView.fieldId);
              const options = [
                ...(directory.optionsService.options[columnView.fieldId] ?? []),
                ...(directory.optionsService.archivedOptions[columnView.fieldId] ?? []),
              ];
              const option = options?.find((opt) => opt.value === control?.value);
              return option?.label ?? '-';
            },
            renderHeader: () => <TableHeader textTKey={`labels:${columnView.fieldId}.label`} />,
            renderCell: (params: GridRenderCellParams<DirectoryRecord>) => {
              const control = params.row.controlsMap.get(columnView.fieldId);

              return (
                <Cell message={control?.error}>
                  <p className={styles.textEllipsis}>{params.value}</p>
                </Cell>
              );
            },
          });

          break;
        }

        case 'MultiComboBox': {
          columns.push({
            ...baseColumn,
            field: columnView.fieldId,
            type: 'string',
            valueGetter: (params: GridValueGetterParams<DirectoryRecord>) => {
              const control = params.row.controlsMap.get(columnView.fieldId);

              assert(control instanceof MultiComboboxField, 'control is not a multicombobox type');

              const options = [
                ...(directory.optionsService.options[columnView.fieldId] ?? []),
                ...(directory.optionsService.archivedOptions[columnView.fieldId] ?? []),
              ];

              const labels: string[] = [];

              control.value.forEach((value) => {
                const correspondingOption = options.find((opt) => opt.value === value);

                if (correspondingOption) {
                  labels.push(correspondingOption.label);
                }
              });

              return labels.join(', ') || '-';
            },
            renderHeader: () => <TableHeader textTKey={`labels:${columnView.fieldId}.label`} />,
            renderCell: (params: GridRenderCellParams<DirectoryRecord>) => {
              const control = params.row.controlsMap.get(columnView.fieldId);

              return (
                <Cell message={control?.error}>
                  <p className={styles.textEllipsis}>{params.value}</p>
                </Cell>
              );
            },
          });

          break;
        }

        case 'Label': {
          columns.push({
            ...baseColumn,
            field: columnView.fieldId,
            type: 'string',
            renderHeader: () => <TableHeader textTKey={`labels:${columnView.fieldId}.label`} />,
            valueGetter: (params: GridValueGetterParams<DirectoryRecord>) => {
              const control = params.row.controlsMap.get(columnView.fieldId);
              const isSimpleLabel = !('refObjectType' in columnView);

              if (isSimpleLabel) {
                return control?.value ?? '-';
              }

              const options = [
                ...(directory.optionsService.options[columnView.fieldId] ?? []),
                ...(directory.optionsService.archivedOptions[columnView.fieldId] ?? []),
              ];
              const option = options?.find((opt) => opt.value === control?.value);
              return option?.label ?? '-';
            },
            renderCell: (params: GridRenderCellParams<DirectoryRecord>) => {
              const control = params.row.controlsMap.get(columnView.fieldId);

              return (
                <Cell message={control?.error}>
                  <p className={styles.textEllipsis}>{params.value}</p>
                </Cell>
              );
            },
          });
        }
      }
    }

    return columns;
  }, [controlViews, directory]);
};
