import { Button, Loader, Modal, Scrollbar } from '@profgeosoft-ui/react';
import { useTranslation } from 'react-i18next';

import { wrap } from 'src/packages/mobx-di/wrap';

import type { CopyToAnotherRoomStore } from './copy-to-another-room.store';
import type { OptionsService } from 'src/services/directory-service/options-service';
import type { IControl } from 'src/services/directory-service/types';

import { FormControl } from '../directory-form-sidebar/features/form/form-control';

import { ConfirmCopyModal } from './confirm-copy-modal';

import styles from './copy-to-another-room-modal.module.scss';

type Props = {
  store: CopyToAnotherRoomStore;
  optionsService: OptionsService;
};

export const CopyToAnotherRoomModal = wrap<Props>(function CopyToAnotherRoomModal({ store, optionsService }) {
  const { t } = useTranslation();

  const { isOpened } = store;

  const handleCancel = () => {
    store.setIsOpened(false);
    store.resetControls();
  };

  return (
    <>
      <Modal
        open={isOpened}
        title={t('directory:components.copyToAnotherRoom.copyingData')}
        onClickOutside={null}
        closeIcon={null}
        headerClassName={styles.modalHeader}
      >
        <div className={styles.modalWrapper}>
          <div className={styles.modalContentWrapper}>
            <Scrollbar>
              <p className={styles.modalFormInfoText}>
                {t('directory:components.copyToAnotherRoom.optionsForCopying')}
              </p>
              <div className={styles.controlsWrapper}>
                {store.sourceControls.map((control) => {
                  const view = store.generalControlViews.find((view) => view.attrName === control.attrName);

                  if (!view) {
                    return null;
                  }

                  const onChange = (_: IControl, value: unknown) => {
                    control.setValue(value);
                    control.setError(null);
                  };

                  return (
                    <FormControl
                      key={control.attrName}
                      controlView={view}
                      control={control}
                      controls={store.sourceControls}
                      optionsService={optionsService}
                      onChange={onChange}
                    />
                  );
                })}
              </div>
              <p className={styles.modalFormInfoText}>{t('directory:components.copyToAnotherRoom.pasteOptions')}</p>
              <div className={styles.controlsWrapper}>
                {store.targetControls.map((control) => {
                  const view = store.generalControlViews.find((view) => view.attrName === control.attrName);

                  if (!view) {
                    return null;
                  }

                  const onChange = (_: IControl, value: unknown) => {
                    control.setValue(value);
                    control.setError(null);
                  };
                  const onBlur = () => {};

                  return (
                    <FormControl
                      key={control.attrName}
                      controlView={view}
                      control={control}
                      controls={store.sourceControls}
                      optionsService={optionsService}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  );
                })}
              </div>
            </Scrollbar>
          </div>

          <div className={styles.modalButttonsWrapper}>
            <Button className={styles.modalButton} variant="danger" onClick={handleCancel}>
              {t('common:cancel')}
            </Button>
            <Button className={styles.modalButton} variant="success" onClick={store.applyCopying}>
              {t('common:apply')}
            </Button>
          </div>

          {store.isLoading && <Loader />}
        </div>
      </Modal>
      <ConfirmCopyModal
        isOpened={store.isConfirmModalOpened}
        onCancel={store.denyCopying}
        onApply={store.confirmCopying}
      />
    </>
  );
});
