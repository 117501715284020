import { Drawer, Table, useGridApiRef } from '@profgeosoft-ui/react';
import { requireService } from '@profgeosoft/di-ez';
import clsx from 'clsx';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { wrap } from 'src/packages/mobx-di/wrap';
import { hasValue } from 'src/packages/utils/has-value';

import type {
  GridCellParams,
  GridColDef,
  GridColumnResizeParams,
  GridFilterModel,
  GridSortItem,
} from '@profgeosoft-ui/react';
import type { MainDirectoryService } from 'src/services/directory-service';
import type { DirectoryRecord } from 'src/services/directory-service/entities/directory-record.entity';
import type {
  TFilterItem,
  TFilterModel,
  TTableSortItem,
} from 'src/services/table-settings-service/table-settings-service';

import { DisplaySettings } from '../display-settings/display-settings';

import { SERVICE_COLUMNS } from './consts';
import { DirectoryTableMediator } from './directory-table-mediator';
import { tableSx } from './table-sx';
import { useGetColumnsGrouping } from './use-get-columns-grouping';
import { useTableColumns } from './use-table-columns';

import styles from './directory-table.module.scss';

type Props = {
  directory: MainDirectoryService;
  isRowSelectionModeOn: boolean;
};

const MIN_VISIBLE_COLUMNS = 3;

export const DirectoryTable = wrap<Props>(function DirectoryTable({ directory, isRowSelectionModeOn }) {
  const displayManager = directory.tableSettingsManager;
  const notifications = requireService('notificationsService');
  const localization = requireService('localizationService');

  const tableApi = useGridApiRef();
  const columns = useTableColumns(directory);
  const groupingModel = useGetColumnsGrouping(directory.view);
  const fields = directory.controlsViewsMap;

  const { t } = useTranslation('directory');

  const handleWidthChange = ({ colDef: { field }, width }: GridColumnResizeParams) => {
    if (!displayManager) {
      return;
    }

    const columns = tableApi.current.getAllColumns();
    const updatedColumns: Record<string, number> = {};

    columns.forEach((column) => {
      if (column.field === field) {
        updatedColumns[field] = width;
      } else if (displayManager.settings.columnsWidth && !displayManager.settings.columnsWidth[column.field]) {
        updatedColumns[column.field] = column.computedWidth;
      }
    });

    displayManager.onWidthChange(updatedColumns);
  };

  const handleTableSettingsClick = () => {
    displayManager?.setSettingsOpen(true);
    tableApi.current.hideColumnMenu();
  };

  const convertToTableSortItems = (model: GridSortItem[]): TTableSortItem[] => {
    const convertedSortItems: TTableSortItem[] = [];

    model.forEach((item) => {
      if (item.sort === 'asc' || item.sort === 'desc') {
        convertedSortItems.push({
          field: item.field,
          sort: item.sort,
        });
      }
    });

    return convertedSortItems;
  };

  const convertToTableFilterItems = (model: GridFilterModel): TFilterModel => {
    const convertedItems: TFilterItem[] = model.items.map((item) => {
      if ((item.value && typeof item.value === 'string') || typeof item.value === 'number') {
        return {
          field: item.field,
          operator: item.operator,
          id: item.field,
          value: item.value,
        };
      } else {
        return { field: item.field, operator: item.operator, id: item.field };
      }
    });

    return { items: convertedItems };
  };

  const getCellClassName = useCallback(
    (params: GridCellParams<DirectoryRecord>): string => {
      const control = params.row.controlsMap.get(params.field);

      if (!control) {
        return '';
      }

      const isSelected =
        displayManager?.selectedCellData &&
        displayManager?.selectedCellData.cellField === params.field &&
        displayManager?.selectedCellData.rowId === params.row.recordId;

      return clsx(isSelected && `${styles.selectedCell} Mui-selected`);
    },
    [displayManager?.selectedCellData],
  );

  useEffect(() => {
    if (!displayManager || !displayManager.settings?.columnsWidth) {
      return;
    }

    const updatedColumns: GridColDef[] = [];

    for (const column of tableApi.current.getAllColumns()) {
      if (displayManager.settings.columnsWidth[column.field] === column.width) {
        continue;
      }

      updatedColumns.push({
        ...column,
        flex: 0,
        width: displayManager.settings.columnsWidth[column.field],
      });
    }

    tableApi.current.updateColumns(updatedColumns);
  }, [tableApi, columns, displayManager, displayManager?.settings]);

  useEffect(() => {
    if (!displayManager) {
      return;
    }

    if (!displayManager.settings.columnsOrderModel) {
      displayManager.onColumnsOrderModelChange(columns.map((columns) => columns.field));
    }
  }, [columns, displayManager, tableApi]);

  useEffect(() => {
    directory.setDirectoryViewMediator(new DirectoryTableMediator(directory, tableApi, notifications, localization));
  }, [directory, localization, notifications, tableApi]);

  useEffect(() => {
    let timer: number | undefined;

    const handleCopy = (event: KeyboardEvent) => {
      if (event.code !== 'KeyC' || (!event.ctrlKey && !event.metaKey)) {
        return;
      }

      if (hasValue(timer)) {
        return;
      }

      const selectedRows = tableApi.current.getSelectedRows();

      if (selectedRows.size < 1) {
        const cellSelection = tableApi.current.unstable_getCellSelectionModel();

        const tableCopyTargetData = (() => {
          if (cellSelection && !!Object.keys(cellSelection).length) {
            return cellSelection;
          }

          if (displayManager?.selectedCellData) {
            return {
              [displayManager.selectedCellData.rowId]: {
                [displayManager.selectedCellData.cellField]: true,
              },
            };
          }

          return null;
        })();

        if (!tableCopyTargetData) {
          return;
        }

        directory.directoryViewMediator?.copySelectedCellData(tableCopyTargetData);

        timer = window.setTimeout(() => {
          timer = undefined;
        }, 500);

        return;
      }

      directory.directoryViewMediator?.copySelectedRowsToClipboard();

      timer = window.setTimeout(() => {
        timer = undefined;
      }, 300);
    };

    document.addEventListener('keydown', handleCopy);

    return () => {
      document.removeEventListener('keydown', handleCopy);
      window.clearTimeout(timer);
    };
  }, [directory.directoryViewMediator, displayManager, tableApi]);

  useEffect(() => {
    const handleFocusIn = ({ field, row }: GridCellParams) => {
      const focusedCellData = { cellField: field, rowId: row.recordId };
      displayManager?.setSelectedCellData(focusedCellData);
    };

    return tableApi.current.subscribeEvent('cellFocusIn', handleFocusIn);
  }, [displayManager, tableApi]);

  if (!displayManager) {
    return null;
  }

  return (
    <>
      <div className={styles.tableWrapper}>
        <Table
          apiRef={tableApi}
          columns={columns}
          rows={directory.records}
          sx={tableSx}
          disableColumnReorder={false}
          serviceColumns={SERVICE_COLUMNS}
          columnsOrderModel={displayManager.settings.columnsOrderModel}
          columnVisibilityModel={displayManager.settings.columnsVisibility}
          sortModel={displayManager.settings.sortingModel}
          pinnedColumns={displayManager.settings.pinnedColumns}
          filterModel={displayManager.settings.filterModel}
          onFilterModelChange={(model) => displayManager.onFilterChange(convertToTableFilterItems(model))}
          onColumnVisibilityModelChange={displayManager.onColumnVisibilityChange}
          onPinnedColumnsChange={displayManager.onPinChange}
          onSortModelChange={(model) => displayManager.onSortChange(convertToTableSortItems(model))}
          onColumnResize={handleWidthChange}
          onColumnsOrderModelChange={displayManager.onColumnsOrderModelChange}
          slotProps={{
            columnMenu: {
              onTableSettingsClick: handleTableSettingsClick,
              minVisibleColumns: MIN_VISIBLE_COLUMNS,
            },
          }}
          className={styles.table}
          checkboxSelection={isRowSelectionModeOn}
          disableAggregation
          disableRowGrouping
          unstable_cellSelection
          loading={directory.isDataFetching || directory.isAdditionalDirectoriesFetching}
          columnGroupingModel={groupingModel}
          experimentalFeatures={{ columnGrouping: true }}
          getRowId={(row: DirectoryRecord) => row.recordId}
          onRowSelectionModelChange={(model) => directory.directoryViewMediator?.setIsCopyAvailable(!!model.length)}
          onClipboardCopy={(_, event) => {
            event.defaultMuiPrevented = true;
          }}
          onCellKeyDown={(_, event) => {
            if (event.code === 'KeyV' && (event.metaKey || event.ctrlKey)) {
              event.defaultMuiPrevented = true;
            }
          }}
          getCellClassName={getCellClassName}
          getRowClassName={({ id }) => {
            const classNames: string[] = [];

            if (typeof id === 'number') {
              const isActive = directory.recordsActivity[id];

              if (isActive) {
                classNames.push(clsx(styles.disactiveRow));
              }
            }

            return '';
          }}
        />
      </div>
      {tableApi.current && (
        <Drawer
          className={styles.displaySettingsWrapper}
          style={{ width: 320 }}
          open={displayManager.isSettingsOpen}
          onClose={() => displayManager.setSettingsOpen(false)}
          title={<p className={styles.title}>{t('displaySettings:title')}</p>}
        >
          <DisplaySettings
            fields={fields}
            columns={columns}
            displayManager={displayManager}
            minVisibleColumns={MIN_VISIBLE_COLUMNS}
          />
        </Drawer>
      )}
    </>
  );
});
