import { action, makeObservable, observable } from 'mobx';

import { agent } from 'src/api/agent';
import { hasValue } from 'src/packages/utils/has-value';

import type { LocalizationService } from '../../localization-service.ts';
import type { DirectoryRecord } from '../entities/directory-record.entity';
import type { IControl, TControlView } from '../types';

import { ValidationServiceApi } from './validation-serivce-api.js';

export class ValidationService {
  private readonly api: ValidationServiceApi;
  private readonly localizationService: LocalizationService;
  private readonly controlViewsMap = new Map<string, TControlView>();
  @observable requiredFields: string[] = [];

  constructor(localizationService: LocalizationService) {
    this.localizationService = localizationService;
    this.api = new ValidationServiceApi(agent);

    makeObservable(this);
  }

  private validateFieldRequiredness(control: IControl): boolean {
    if (!this.requiredFields.includes(control.fieldId)) {
      return true;
    }

    if (!hasValue(control.value)) {
      return false;
    }

    return true;
  }

  @action.bound
  setRequiredFields(reqFields: string[]): void {
    this.requiredFields = reqFields;
  }

  setControlViews(views: TControlView[]): void {
    views.forEach((controlView) => this.controlViewsMap.set(controlView.fieldId, controlView));
  }

  validateControl(control: IControl): boolean {
    if (!this.validateFieldRequiredness(control)) {
      control.setError(this.localizationService.t('directory:errors.validation.requiredField'));

      return false;
    }

    return true;
  }

  validateRecord(record: DirectoryRecord): boolean {
    let isValid = true;

    record.controls.forEach((control) => {
      if (!this.validateControl(control)) {
        isValid = false;
      }
    });

    return isValid;
  }
}
